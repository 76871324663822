import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "Qualification Approved" }
const _hoisted_2 = { class: "Approved_p" }
const _hoisted_3 = { class: "Approved_icon" }
const _hoisted_4 = {
  key: 0,
  class: "Approved_TYC"
}
const _hoisted_5 = { class: "Approved_TYC_check" }
const _hoisted_6 = {
  key: 1,
  class: "Approved_TYC_Error Approved_TYC_Error_Padding"
}
const _hoisted_7 = {
  key: 2,
  class: "Approved_TYC_ErrorAPI Approved_TYC_Error_Padding"
}
const _hoisted_8 = {
  key: 3,
  class: "Approved_TYC_ErrorPDF Approved_TYC_Error_Padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode("Habilitación exitosa "),
        _createElementVNode("em", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, { icon: "check-circle" })
        ])
      ]),
      ($setup.aplicativo != '1')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_checkbox, {
                modelValue: $setup.checkTerminos,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.checkTerminos) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createTextVNode(" He leído y autorizo el "),
            _createElementVNode("a", {
              class: "Approved_TYC_link",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.obtenerTTD()))
            }, " Tratamiento de mis datos personales ")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.Validarcheck())),
        class: "Approved_button"
      }, "Continuar"),
      ($setup.Error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, "*No es posible continuar hasta no haber leído y autorizado el Tratamiento de Datos Personales"))
        : _createCommentVNode("", true),
      ($setup.ErrorAPI)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Estamos actualizando tu aprobación del Tratamiento de Datos. Vuelve a intentarlo más tarde para completar el proceso."))
        : _createCommentVNode("", true),
      ($setup.ErrorPDF)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, "Actualmente, la descarga del archivo PDF está tomando más tiempo de lo habitual. Te sugerimos intentarlo nuevamente más tarde."))
        : _createCommentVNode("", true)
    ]),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          type: "modern"
        }))
      : _createCommentVNode("", true)
  ], 64))
}