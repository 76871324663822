<template>  
  <div class="divContainerButton" v-if="featureFlag">
    <DescargarExcel  
      :data="errores"
      :name="'Errores_Transmisión_' + periodo"
      :json_fields="json_fields">
    </DescargarExcel>
  </div>
  <DataTable 
    ref="dt"
    responsiveLayout="scroll" 
    :filters="datos?.contiene"
    :rows="5" 
    :value="errores" 
    :paginator="true" 
    :rowsPerPageOptions="[5,10,20,50,100]"
    :exportFilename = "'Errores_Transmisión_' + periodo">

    <Filtro ref="datos"></Filtro>
    <Column field="tipoIdentificacion" header="Tipo ID" :sortable="true" bodyClass="datatable-text-center"></Column> 
    <Column field="numeroIdentificacion" header="No. ID" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="mensajeError" header="Descripción" :sortable="true"></Column>
    <Column field="cune" header="Cune" :sortable="true" bodyClass="datatable-text-center"></Column>

  </DataTable>

</template>

<script>

import { onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import Filtro from '../layout/Filtro.vue';
import { useStore } from "vuex";
import DescargarExcel from "@/components/DetalleTransmission/DescargarExcel.vue";

export default {
  components: {
    Filtro,
    DescargarExcel
  },
  props: {
    errores: {},
    periodo: String,
  },
  setup(props) {
    const store = useStore();
    const errores = ref('');
    const datos = ref();
    const periodo = ref('');

    let featureFlag = ref(false);
    
    function GetFeatureFlag()
    {
    const data = 
    {
      token: localStorage.getItem('Tokensin'),
      featureFlagName: 'PBI-232825'
    }
    store.dispatch('GetStatusFeatureFlag', data).then(response => {
      featureFlag.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    onMounted(() => {
      periodo.value = props.periodo;
      errores.value = props.errores;
      GetFeatureFlag()
    });
    
    return {
      errores,     
      periodo,
      datos,
      featureFlag,
      GetFeatureFlag,
      json_fields: {
                "Tipo ID": "tipoIdentificacion",
                "No. ID": "numeroIdentificacion",
                "Descripción": "mensajeError",
                "cune": "cune"
              }         
    };
  },
};

</script>
 