
import { defineComponent, ref, computed, onMounted } from "vue";
import { useMutations } from "@/store/helper";
import { useStore } from "vuex";
import router from "@/router";
import { normalizarTexto } from "@/helpers/helpers";

export default defineComponent({
  name: "Accordion",
  props: {
    accordion: String,
    showStartButton: Boolean,
  },
  setup(props) {
    const pregunta = ref("");
    const respuesta = ref("");
    const mostrarPregunta = ref(false);
    const preguntasFrecuentes = ref();

    const store = useStore();
    const parametroBusqueda = ref("");
    const { setStateMenu } = useMutations(["setStateMenu"]);
    const { setStateLoading } = useMutations(["setStateLoading"]);
    setStateMenu(true);

    const mostrarRespuesta = (preg: string, resp: string) => {
      pregunta.value = preg;
      respuesta.value = resp;
      mostrarPregunta.value = true;
    };

    const resultadosBusqueda = computed(() => {
      if (!parametroBusqueda.value) return preguntasFrecuentes.value;

      const busquedaNormalizada = normalizarTexto(parametroBusqueda.value);

      return preguntasFrecuentes.value
        .map((categoria) => {
          const preguntasFiltradas = categoria.preguntasPorCategoria.filter(
            (pregunta) =>
              normalizarTexto(pregunta.pregunta).includes(busquedaNormalizada)
          );

          if (
            normalizarTexto(categoria.nombreCategoria).includes(
              busquedaNormalizada
            ) ||
            preguntasFiltradas.length > 0
          ) {
            return {
              ...categoria,
              preguntasPorCategoria: preguntasFiltradas,
            };
          }
          return null;
        })
        .filter((categoria) => categoria !== null);
    });

    const toggleCategoria = (categoriaFiltrada: any) => {
      const categoriaOriginal = preguntasFrecuentes.value.find(
        (categoria) => categoria.categoriaId === categoriaFiltrada.categoriaId
      );
      if (categoriaOriginal) {
        categoriaOriginal.desplegado = !categoriaOriginal.desplegado;
      }
    };

    const noHayResultados = computed(() => {
      return (
        parametroBusqueda.value &&
        (resultadosBusqueda.value === undefined ||
          resultadosBusqueda.value.length === 0)
      );
    });

    const hayPreguntas = (categoria: any) => {
      return categoria.preguntasPorCategoria.length > 0;
    };

    function GoToTransmission() {
      router.push({
        name: "Transmission",
      });
    }

    function ObtenerPreguntasFrecuentes() {
      setStateLoading(true);
      let queryParams = {
        Token: localStorage.getItem("Tokensin"),
      };
      store
        .dispatch("ConsultarPreguntasFrecuentes", queryParams)
        .then((response) => {
          if (response.request.status == 200) {
            preguntasFrecuentes.value = response.data;
            setStateLoading(false);
          }
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error);
        });
    }

    onMounted(() => {
      ObtenerPreguntasFrecuentes();
    });

    return {
      parametroBusqueda,
      resultadosBusqueda,
      mostrarPregunta,
      pregunta,
      respuesta,
      mostrarRespuesta,
      toggleCategoria,
      props,
      preguntasFrecuentes,
      noHayResultados,
      hayPreguntas,
      GoToTransmission,
    };
  },
});
