export enum Tab {
    Aprobada = 0,
    Rechazada = 1,
    Faqs = 2,
    Historico = 3
}

export enum TabDetail {
    Trabajador = 0,
    Liquidacion = 1,
    Deducciones = 2
}

export enum TabTransmission {
    Liquidacion = 0,
    Deducciones = 1
}

export enum TabLiquidacion {
    Empleado = 0,
    DatosEmpresa = 1,
    NominaElectronica = 2,
    Devengos = 3,
    Deducciones = 4,
    TotalNomina = 5
}

export enum TabDeducciones {
    HorasExtras = 0,
    Incapacidades = 1,
    Licencias = 2,
    Vacaciones = 3,
    HuelgasLegales = 4,
    OtrosConceptosDeducciones = 5
}


export enum EstadoDetalleNomina {
    CreadoEmpleadoPT = 1,
    ErrorEmpleadoPT = 2,
    PorCrearEmpleadoPT = 3,
    ErrorTransmisionPT = 4,
    CompletadoTransmisionPT = 5,
    RechazadoDIAN = 6,
    AprobadoDIAN = 7,
    ErrorConsultaDIAN = 8,
    Eliminada = 9,
    Reemplazada = 10
}

export enum EstadoEntradaNomina {
    NoDefinido = 0,
    EnStorage = 1,
    EnLectura = 2,
    ErrorLectura = 3,
    LecturaCompletada = 4,
    CargandoEmpleados = 5,
    ErrorCargueEmpleados = 6,
    EnValidacion = 7,
    ErrorValidacion = 8,
    ValidacionCompletada = 9,
    EnCreacionoModificacionEmpleadosPT = 10,
    ErrorCreacionoModificacionEmpleadosPT = 11,
    CreacionoModificacionEmpleadoPTCompletada = 12,
    EnTransmisionNominasPT = 13,
    ErrorParcialTransmisionNominasPT = 14,
    ErrorTotalTransmisionNominasPT = 15,
    TransmisionNominasPTCompletada = 16,
    RechazadoParcialDIAN = 17,
    RechazadoTotalDIAN = 18,
    AprobadoDIAN = 19,
    ErrorInternoCargue = 20,
    EnConsultaDIAN = 21,
    SinDocumentosOVencidos = 22,
    ConDocumentosInsuficientes = 23
}

export enum TipoNomina {
    NoDefinido = '0',
    NominaUnica = '102',
    NominaAjuste = '103'
}

export enum TipoNota {
    NoAplica = '0',
    Reemplazar = '1',
    Eliminar = '2'
}

export enum TipoParametrica {
    Incapacidades = 1,
    TiposTrabajador = 2,
    SubtiposTrabajador = 3,
    TiposContrato = 4,
    MetodosPago = 5,
    TiposMoneda = 6,
    ClasesDocumento = 7,
    TiposNominaAjuste = 8,
    TiposLicencia = 9,
    PeriodosNomina = 10,
    TiposCuenta = 11,
    Vacaciones = 12,
    TiposDocumento = 13,
    Paises = 14,
    Departamentos = 15,
    Municipios = 16,
    Decision = 17,
    FormaPago = 18
}

export enum TipoDevengo {
    Transporte = 0,
    Comisiones = 1,
    Prima = 2,
    Cesantias = 3,
    Compensaciones = 4,
    Bonos = 5,
    PagoTerceros = 6
}

export enum TipoDeduccion {
    SaludPension = 0,
    FondoSP = 1,
    SSPTA = 2,
    OtrasDeducciones = 3
}

export enum TipoMarca{
    CompraPlanes = 1,
}