import moment from "moment";
import { useStore } from "vuex";

/**
 * @param {string} periodo en formato 2023-10.
 * @return {string} periodo en formato Octubre-2023.
**/
export function obtenerPeriodo(periodo: string): string {
    const fechaPeriodo: string[] = (`${periodo}-01`).split("-"); //yyyy-mm-dd

    const fecha: Date = new Date(
        Number(fechaPeriodo[0]),     //Año
        Number(fechaPeriodo[1]) - 1,  //Mes
        Number(fechaPeriodo[2])      //Día
    );

    const mes: string = fecha.toLocaleString('default', { month: 'long' });
    periodo = mes + " - " + fechaPeriodo[0];

    return periodo.charAt(0).toUpperCase() + periodo.slice(1);
}

/**
 * @param {any} valor al que se requiere asignar formato pesos.
 * @return {string} Valor con formato de moneda o vacío si el dato proporcionado no es un número válido.
**/
export function AsignarFormatoPesos(valor: any): string {
    if (isNaN(valor) || valor == '') {
        return ''
    }
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });
}

/**
 * @param {contentType} contentType: Tipo de archivo.
 * @param {base64} base64: Representación del archivo en base64
 * @param {nombreArchivo} nombreArchivo: nombre del archivo con extensión.
**/
export function DescargarArchivo(contentType: string, base64: string, nombreArchivo: string) {

    const linkSource = `data:${contentType};base64,${base64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = nombreArchivo;
    downloadLink.click();

}

export function ObtenerEstado(valor: any): string {
    return valor ? "SI" : "NO";
}

export function DatetimeToDate(datetime: any): any {
    return datetime ? moment(datetime).format("YYYY-MM-DD") : "";
}

export function DateToPeriod(datetime: string): string {
    return datetime ? moment(datetime).format("YYYY-MM") : "";
}

export function ConvertToDatetime(date: any): any {
    return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
}

export function Split(value: string, position: number, char: string) {
    return value.split(char)[position];
}

export function CampoNuloOVacio(campo: string): boolean {
    return campo == null || campo == "" || campo == undefined;
};

export function CompletarDigitos(valor: string, longitud: number) {
    while (valor.length < longitud)
        valor = `0${valor}`;
    return valor;
}

export function GetAndSetStatusFeatureFlag(numberPBI:string) {
    const store= useStore();
    const dataGetStatusFF = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-"+numberPBI,
    };
    store.dispatch("GetStatusFeatureFlag", dataGetStatusFF)
    .then((response) => {
        sessionStorage.setItem("showFeaturePBI"+numberPBI, String(response.data));
    })
    .catch((err) => {
        console.error("Error al obtener estado del feature flag");
        sessionStorage.setItem("showFeaturePBI"+numberPBI, String(false));
    });
}

/**
 * Función para eliminar caracteres diacríticos (tíldes, diéresis, entre otros) de una cadena de texto.
 * Ejemplo: Nómina => Nomina
**/
export function normalizarTexto(texto: string) {
    return texto
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};