import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu_navbar" }
const _hoisted_2 = { class: "menu_link" }
const _hoisted_3 = { class: "menu_link" }
const _hoisted_4 = { class: "menu_link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 17 }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.GotoTransmission())),
                class: "menu_link"
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "home" }),
                _createTextVNode(" Nómina Electrónica")
              ]),
              _createVNode(_component_el_dropdown, null, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.GoToEmployerContact()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Datos Empleador ")
                        ]),
                        _: 1
                      }),
                      ($setup.mostrarConfiguracion)
                        ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: 0,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.GoToConfiguration()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Configuración ")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, [
                    _createVNode(_component_font_awesome_icon, { icon: "building" }),
                    _createTextVNode(" Empleador ")
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_dropdown, null, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.GoToPlans()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Comprar ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_dropdown_item, {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.GoToStatePlan()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Estado ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_font_awesome_icon, { icon: "file" }),
                    _createTextVNode(" Planes ")
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_4, [
                _createElementVNode("button", {
                  class: "Button_white",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.GoToFaqs && $setup.GoToFaqs(...args)))
                }, " Preguntas frecuentes ")
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}