<template>
  <div class="divContainerButton" v-if="featureFlag">
    <DescargarExcel  
      :data="errores"
      :name="'Errores_Transmisión_' + periodo"
      :json_fields="json_fields">
    </DescargarExcel>
  </div>
  <DataTable 
    ref="dt"
    responsiveLayout="scroll" 
    :filters="datos?.contiene"
    :rows="5" 
    :value="errores" 
    :paginator="true" 
    :rowsPerPageOptions="[5,10,20,50,100]"
    :exportFilename = "'Errores_Transmisión_' + periodo">

    <Filtro ref="datos"></Filtro>
    <Column field="CeldaHojaDocumento" header="Campo" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="HojaArchivo" v-if="hojaArchivo" header="Ubicación" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="HojaDocumento" v-if="hojaDocumento" header="Ubicación" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="ValorErrado" header="Valor reportado" bodyClass="datatable-text-center" :sortable="true"></Column>
    <Column field="TipoDatoEsperado" header="Valor esperado" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="MensajeErrorUsuario" header="Descripción" :sortable="true"></Column>

  </DataTable>
  
</template>

<script>
import { onMounted } from "vue";
import { ref } from "@vue/reactivity";
import Filtro from "../layout/Filtro.vue";
import { useStore } from "vuex";
import DescargarExcel from "@/components/DetalleTransmission/DescargarExcel.vue";

export default {
  name: "ErrorCargueNomina",
  components: {
    Filtro,
    DescargarExcel
  },
  props: {
    errores: {},
    periodo: String,
  },
  setup(props) {
    const store = useStore();
    const errores = ref("");
    const datos = ref();
    const hojaDocumento = ref(false);
    const hojaArchivo = ref(false);
    const periodo = ref('');

    let featureFlag = ref(false);

    function GetFeatureFlag() {
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-232825",
      };
      store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          featureFlag.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    onMounted(() => {
      periodo.value = props.periodo;
      if (props.errores?.mensajeError != null) {
        errores.value = JSON.parse(props.errores.mensajeError);
        for (const error of errores.value) {
          hojaArchivo.value = error.hasOwnProperty("HojaArchivo");
          hojaDocumento.value = error.hasOwnProperty("HojaDocumento");
        }
      }
      GetFeatureFlag();
    });

    return {
      errores,
      periodo,
      datos,
      hojaArchivo,
      hojaDocumento,
      featureFlag,
      GetFeatureFlag,
      json_fields: {
                "Campo": "CeldaHojaDocumento",              
                "Ubicación": "HojaArchivo",
                "Ubicación": "HojaDocumento",
                "Valor reportado": "ValorErrado",
                "Valor esperado": "TipoDatoEsperado",
                "Descripción": "MensajeErrorUsuario"
              }   
    };
  },
};
</script>
 