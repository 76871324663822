
import {useStore} from "vuex"
import{useMutations} from '@/store/helper'
import { onMounted } from "vue";
import { ref } from '@vue/reactivity';
import { watch} from '@vue/runtime-core';
import router from '@/router';
import Spinner from "@/components/layout/Spinner.vue"
import { DescargarArchivo } from "@/helpers/helpers";
export default {
  name:"Approved",
    components:{
      Spinner
    },
    setup() {
      const loading = ref(false); 
      const store= useStore();
      const dialogVisible=ref(false);
      const checkTerminos= ref(false);
      const Error= ref(false);
      const ErrorAPI= ref(false);
      const ErrorPDF = ref(false);
      const GotoCorreoProteccionDeDatos= ref("mailto:protecciondedatos@aportesenlinea.com");
      const Gotoaportes= ref("https://www.aportesenlinea.com");
      const styleCheckError= ref({'border': '3px solid red' });
      const{setTratamientoDatos} = useMutations(['setTratamientoDatos']);
      const aplicativo = localStorage.getItem('AplicativoOrigen');
      const idDocumento = ref();

      function goToDashboard(){
        loading.value = true;
        var data={
            Identificacion:localStorage.getItem('Identificacion'),
            Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
            IdDocumentoParaAprobar:idDocumento.value      
         }  
        setTratamientoDatos(data);
          store.dispatch('AprobarTTD',localStorage.getItem('Tokensin')).then(()=>{
            ErrorAPI.value=false;    
            ErrorPDF.value=false; 
            store.dispatch('obtenerUrlDebidaDiligencia').then((response) => {
              window.open(response,"_self");
            }).catch(error=>{
                  console.log(error);
                  loading.value = false; 
            });
          }).catch(error=>{
                  console.log(error);
                  ErrorAPI.value=true;
                  ErrorPDF.value=false;
                  loading.value = false; 
          });
           
        }

      function Validarcheck(){
        if(aplicativo == '1'){
          router.push({name:'validationNeL'});
        }
        else{
          if(checkTerminos.value){
             goToDashboard();
          }else{
             Error.value=true;
             ErrorAPI.value=false;
          }
          ErrorPDF.value=false; 
        }
      }
      watch(()=>checkTerminos.value,()=>{
        if(checkTerminos.value){
            Error.value=false;
          }else{
            Error.value=true;
            ErrorAPI.value=false;
          }
          ErrorPDF.value=false; 
      });

      function obtenerTTD()
    {
      loading.value = true;
      let params = {
            token : localStorage.getItem("Tokensin"),
             DocumentoDescargar: idDocumento.value
          };

      store
      .dispatch("DescargarDocumentosTYC_TTD", params)
      .then((response) => {        
         DescargarArchivo(
             "application/pdf",
             response.data.archivo,
             "Declaración tratamiento de datos"
             ); 
      loading.value = false;         
      })
      .catch((error) => {
        console.log(error);
        loading.value = false; 
        ErrorPDF.value = true;
        Error.value = false;
        ErrorAPI.value = false;
      });
    };

    function consultarDocumentosTyc_Ttd()
        {
          let ParametrosEmpleador = {
                        tipoIdentificacion: localStorage.getItem('TipoIdentificacion'),
                        numeroIdentificacion: localStorage.getItem('Identificacion'),  
                        token: localStorage.getItem('Tokensin')              
                    }
          store.dispatch('ConsultarDocumentosTyc_Ttd', ParametrosEmpleador).then(
            (res) => {            
              idDocumento.value = res.data.find((doc) => doc.nombreDocumento.includes("Tratamiento de Datos Personales"))?.id        
            }
        ).catch((err) => {
          console.log(err);  
        })
        }
      
      onMounted(() => {
      consultarDocumentosTyc_Ttd();  
    })

    return{
      dialogVisible,
      checkTerminos,
      styleCheckError,
      Error,
      ErrorAPI,
      ErrorPDF,
      Validarcheck,
      Gotoaportes,
      GotoCorreoProteccionDeDatos,
      aplicativo,
      idDocumento,
      obtenerTTD,
      loading
    };
  }
    
};
