<template>
  <div class="Contract">
      <div class="Contract_containerTitle">
        <h1 class="Contract_containerTitle_title"> <font-awesome-icon :icon="['fas', 'shopping-cart']" class="txtTitle"/> Nuestro servicio </h1>
      </div>
      <div class="Contract_container">
        <div class="Contract_containerContent" >
          <div class="Contract_containerContent_textIcon"> <font-awesome-icon :icon="['fas', 'address-card']" class="Contract_containerContent_textIcon_icon" /> <p class="Contract_containerContent_textIcon_text" >Habilitación de la empresa frente a la DIAN.</p> </div>
          <div class="Contract_containerContent_textIcon"> <font-awesome-icon :icon="['fas', 'chalkboard-teacher']" class="Contract_containerContent_textIcon_icon" /> <p class="Contract_containerContent_textIcon_text" >Transmisión de documentos de soporte de pago.</p> </div>
          <div class="Contract_containerContent_textIcon"> <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" class="Contract_containerContent_textIcon_icon" /> <p class="Contract_containerContent_textIcon_text" >Cargue de información.</p> </div>
          <div class="Contract_containerContent_textIcon"> <font-awesome-icon :icon="['fas', 'edit']" class="Contract_containerContent_textIcon_icon" /> <p class="Contract_containerContent_textIcon_text" > &nbsp;Firma digital.</p> </div>
          <div class="Contract_containerContent_textIcon"> <font-awesome-icon :icon="['fas', 'database']" class="Contract_containerContent_textIcon_icon" /> <p class="Contract_containerContent_textIcon_text" >&nbsp; Almacenamiento.</p> </div>
           
          <p class="Contract_containerContent_description">* El paquete de documentos adquirido tendrá una vigencia máxima de un año a partir de la compra.</p>
          <div class="Contract_containerContent_TYC" >
              <div class="Contract_containerContent_TYC_check"> <el-checkbox v-model="checkTerminos" class="CheckBoxTYC"></el-checkbox></div> 
                  He leído en su totalidad y acepto los 
                  <strong class="Contract_link" @click="obtenerTYC()"> 
                  términos y condiciones del servicio. </strong>
          </div>  
            <input type="button" @click="Validarcheck()" value="Continuar" class="Contract_containerContent_button" />
            <p v-if="Error" class="Contract_containerContent_TYC_error" >*No es posible continuar hasta no haber leído y aceptado los términos y condiciones del servicio</p>
            <p v-if="ErrorAPI" class="Contract_containerContent_TYC_errorAPI" >Tu aprobación de los Términos y Condiciones está en proceso. Por favor, inténtalo de nuevo más tarde.</p>
            <p v-if="ErrorPDF" class="Contract_containerContent_TYC_errorPDF" >Actualmente, la descarga del archivo PDF está tomando más tiempo de lo habitual. Te sugerimos intentarlo nuevamente más tarde.</p>

        </div>
        <div class="Contract_containerImage" >
          <img class="Contract_containerImage_image" alt="Imagen" src="../assets/img/contract.png"> 
        </div>
      </div>   
  </div>
   <Spinner v-if="loading" type="modern"></Spinner>
</template>

<script>
import router from '@/router';
import {useStore} from "vuex";
import { onMounted } from "vue";
import { ref } from '@vue/reactivity';
import{useMutations} from '@/store/helper';
import { watch} from '@vue/runtime-core';
import Spinner from "@/components/layout/Spinner.vue"
import { DescargarArchivo } from "@/helpers/helpers";
export default {
  name: "Contract",
  components: {
      Spinner
  },
  setup() {
    const loading = ref(false);  
    const store= useStore();
    const dialogVisible=ref(false);
    const checkTerminos= ref(false);
    const Error= ref(false);
    const ErrorAPI= ref(false);
    const ErrorPDF = ref(false);
    const styleCheckError= ref({'border': '3px solid red', 'background-color':'red'});
    const{setTyC} = useMutations(['setTyC']);
    const documentosTyc = ref([]);
    const idDocumento = ref();
    function Validarcheck(){
        if(checkTerminos.value){
            goToDashboard();
            Error.value=false;
        }else{
            Error.value=true;
        }
        ErrorAPI.value=false;
      }
      watch(()=>checkTerminos.value,()=>{
        if(checkTerminos.value){
            Error.value=false;
          }else{
            Error.value=true;           
          }
          ErrorAPI.value=false;
          ErrorPDF.value=false;
      }); 
    function goToDashboard(){
      loading.value = true;
      var data={
          Identificacion:localStorage.getItem('Identificacion'),
          Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
          IdDocumentoParaAprobar:idDocumento.value
        }  
      setTyC(data);
      store
      .dispatch("AprobarTyC", localStorage.getItem("Tokensin"))
      .then(() => {
        ErrorAPI.value=false;
        ErrorPDF.value=false;
        store.dispatch('ValidateContactData', localStorage.getItem('Tokensin')).then(
          (res) => {
            localStorage.setItem('TieneDatosContacto', res.data);
            localStorage.setItem('DatosContactoRedireccion', 'Plans');
            if (res.data){
              router.push({name:'Plans'});
            } else {
              router.push({name:'EmployerContact'});
            }
          }
        ).catch((err) => {
          loading.value = false;
          console.log(err);
          ErrorPDF.value=false;
          router.push({name:'EmployerContact'});
        })
      })
      .catch((error) => {
        loading.value = false;
        console.log(error);
        ErrorAPI.value=true;
        ErrorPDF.value=false;
      });
    };
    function obtenerTYC()
    {
      loading.value = true;
      let params = {
            token : localStorage.getItem("Tokensin"),
             DocumentoDescargar: idDocumento.value
          };

      store
      .dispatch("DescargarDocumentosTYC_TTD", params)
      .then((response) => {        
         DescargarArchivo(
             "application/pdf",
             response.data.archivo,
             "Terminos y Condiciones"
             );
             loading.value = false;
      })
      .catch((error) => {
        loading.value = false;
        console.log(error);
        ErrorPDF.value=true;
        Error.value=false;
        ErrorAPI.value = false;
      });
    };

    onMounted(() => {
      idDocumento.value = store.state.datosTycTtd.find((doc) => doc.nombreDocumento.includes("Terminos y Condiciones"))?.id 
    })
    return {
        dialogVisible,
        checkTerminos,
        styleCheckError,
        Error,
        ErrorAPI,
        ErrorPDF,
        Validarcheck,
        goToDashboard,
        loading,
        obtenerTYC,
        documentosTyc,
        idDocumento
    };
  },
};
</script>